const slugify = require('slugify')

const slugifyText = (text) => {
    const slugifySettings = {
        lower: true,
        locale: 'hu',
        strict: true,
        remove: /[*+~.()'"!:@]/g
      }

    return slugify(text, slugifySettings)
}

const nicefyDate = (date) => {
  const months = ["Január","Február","Március","Április","Május","Június","Július","Augusztus","Szeptember","Október","November","December"]
  
  const dateParts = date.split('T')[0].split('-')
  const year = dateParts[0];
  const month = months[dateParts[1] - 1]
  const day = dateParts[2]
  const niceDate = "" + year + ". " + month + " " + day + "."

  return niceDate
}

const paginationPageIndexes = (itemPerPage, allItems) => {
  const leftOver = allItems % itemPerPage
  const pagesMin = (allItems - leftOver) / itemPerPage
  const pagesMax = leftOver > 0 ? pagesMin + 1 : pagesMin
  
  // N.B. starts from 1
  const pages = Array.from({length : pagesMax}, (_, v) => v + 1)

  return pages
}

const paginationPageSlugs = (baseSlug, page, allPages) => {
  const prev = page === 1 ? null : page - 1
  const next = page === allPages ? null : page + 1

  const prevSlug = prev === null ? null : `${ baseSlug }/${ prev }`
  const nextSlug = next === null ? null : `${ baseSlug }/${ next }`

  return ({
    prev: prevSlug,
    next: nextSlug
  })
}

const categoryIdToName = (id) => {
  const ids = {
    1: 'Belföld',
    2: 'Külföld',
    3: 'Gazdaság',
    4: 'TechTud',
    5: 'Kultúra',
    6: 'Sport',
    7: 'Bulvár',
    8: 'Életmód',
    9: 'Időjárás'
  }

  return ids[id]
}

const getCover = (article) => {
  if (article.cover === null || article.cover.childImageSharp === null || article.cover.childImageSharp.fluid === null) {
      return null
  } else {
      return article.cover.childImageSharp.fluid
  }
}

module.exports.slugifyText = slugifyText
module.exports.nicefyDate = nicefyDate
module.exports.paginationPageIndexes = paginationPageIndexes
module.exports.paginationPageSlugs = paginationPageSlugs
module.exports.categoryIdToName = categoryIdToName
module.exports.getCover = getCover