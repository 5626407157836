import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { slugifyText } from "../../utils/utils"

const getCover = (article) => {
    if (article.cover === null || article.cover.childImageSharp === null || article.cover.childImageSharp.fluid === null) {
        return null
    } else {
        return article.cover.childImageSharp.fluid
    }
}

export default function Box({ box, article }) {
    const title = article.title
    const excerpt = article.excerpt
    const category = article.category.name
    const cover = getCover(article)
    const slug = `/${ slugifyText(category) }/${ slugifyText(title) }`

    const box1 =
        <div className="article article_hu">
            <div>
                <a href={slug} className="display-block" title={title}>
                    { cover && <Img className="image" fluid={cover} alt={title} /> }
                    <span className="text">
                        <span className="category category_10">{ category }</span>
                        <span className="hgroup">
                            <h2>{ title }</h2>
                            <span><p>{ excerpt }</p></span>
                        </span>
                    </span>
                </a>
            </div>
        </div>

    const boxes = {
        1: box1
    }

    return boxes[box]
}